// App.js
import './App.css';
import { ParlayForm } from './components/ParlayForm';
import { ArbForm } from './components/ArbForm';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import React from 'react';

function HomePage() {
  const handleButtonClick = (buttonName) => {
    logEvent(analytics, 'button_click', {
      button_name: buttonName,
    });
  };

  return (
    <div>
      <h1 className="homePageHeader">Welcome to Arbulator</h1>
      <div className="buttonsContainer">
        <Link to="/parlay">
          <button className="homePageButton" onClick={() => handleButtonClick('Parlay Calculator')}>
            Parlay Calculator
          </button>
        </Link>
        <Link to="/arbitrage">
          <button className="homePageButton" onClick={() => handleButtonClick('Arbitrage Calculator')}>
            Arbitrage Calculator
          </button>
        </Link>
        <button
          className="contactButton"
          onClick={() => {
            handleButtonClick('Contact Me');
            window.location.href = 'mailto:anthony@pulcer.net';
          }}
        >
          Contact me
        </button>
        <button
          className="contactButton"
          onClick={() => {
            handleButtonClick('Follow on X');
            window.open('https://twitter.com/AnthonyHowell88', '_blank');
          }}
        >
          Follow me on X
        </button>
        <button
          className="contactButton"
          onClick={() => {
            handleButtonClick('Website');
            window.open('https://pulcer.net', '_blank');
          }}
        >
          Website
        </button>
      </div>
    </div>
  );
}

function App() {
  React.useEffect(() => {
    logEvent(analytics, 'page_view');
  }, []);

  return (
    <Router>
      <div className="homePageContainer">
        <Routes>
          <Route index element={<HomePage />} />
          <Route
            path="parlay"
            element={
              <div style={{ display: 'block' }}>
                <h1 className="App-header">Parlay Calculator</h1>
                <ParlayForm />
              </div>
            }
          />
          <Route
            path="arbitrage"
            element={
              <div style={{ display: 'block' }}>
                <h1 className="App-header">Arbitrage Calculator</h1>
                <ArbForm />
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;