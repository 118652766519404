import React from "react";

const ButtonGroup = ({ buttons, handleClick, oddsTypeButtonSelectedId }) => (
    <>
        {buttons.map((oddsButtonLabel, oddsButtonIndex) => (
            <button
                key={oddsButtonIndex}
                onClick={() => handleClick(oddsButtonIndex)}
                className={oddsButtonIndex === oddsTypeButtonSelectedId ? "customButton active" : "customButton"}
            >
                {oddsButtonLabel}
            </button>
        ))}
    </>

);

export default ButtonGroup;
