import { useState } from "react";
import ButtonGroup from "./ButtonGroup";
import { parlayCalc } from "./ParlayCalculator";
import './formstyles.css';
import { useNavigate } from "react-router-dom";

export function ParlayForm() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/");
  };

  const [eventOdds, setEventOdds] = useState(Array(8).fill(''));
  const [wagerAmount, setWagerAmount] = useState(100);
  const [oddsTypeButtonSelectedId, setOddsTypeButtonSelectedId] = useState(1);

  const decOddsAnswer = Math.round(parlayCalc.multiplyDecArray(eventOdds) * wagerAmount * 100) / 100;
  const mlOddsAnswer = Math.round(parlayCalc.multiplyMlArray(eventOdds) * wagerAmount * 100) / 100;
  const fracOddsAnswer = Math.round(parlayCalc.multiplyFracArray(eventOdds) * wagerAmount * 100) / 100;

  const decOddsAnswerString = `$${parlayCalc.formatOdds(decOddsAnswer)} `;
  const mlOddsAnswerString = `$${parlayCalc.formatOdds(mlOddsAnswer)}`;
  const fracOddsAnswerString = `$${parlayCalc.formatOdds(fracOddsAnswer)}`;

  const arrayTrue = eventOdds.every((val) => val === eventOdds[0]) && eventOdds[0] === 1;

  const decParlayOdds = Math.round((decOddsAnswer / wagerAmount) * 100) / 100;
  const mlParlayOdds = parlayCalc.totalParlayMlOdds(mlOddsAnswer / wagerAmount);
  const fracParlayOdds = parlayCalc.totalParlayFracOdds(Math.round(((fracOddsAnswer - wagerAmount) / wagerAmount) * 100) / 100);

  const handleEventOddsChange = (index, value) => {
    setEventOdds((prevOdds) => {
      const newOdds = [...prevOdds];
      newOdds[index] = value;
      return newOdds;
    });
  };

  const eraseEventOdds = (index) => {
    setEventOdds((prevOdds) => {
      const newOdds = [...prevOdds];
      newOdds[index] = '';
      return newOdds;
    });
  };

  const handleWagerChange = (e) => {
    setWagerAmount(e.target.value);
  };

  function handleOddsButtonClick(oddsButtonIndex) {
    setOddsTypeButtonSelectedId(oddsButtonIndex);
  }

  const clearAll = () => {
    setWagerAmount(100);
    setEventOdds(Array(8).fill(''));
  };

  const returnPayout = () => {
    if (oddsTypeButtonSelectedId === 0) {
      if (isNaN(decOddsAnswer)) {
        return parlayCalc.errorMessage;
      } else if (arrayTrue) {
        return '';
      }
      return decOddsAnswerString;
    } else if (oddsTypeButtonSelectedId === 1) {
      if (isNaN(mlOddsAnswer)) {
        return parlayCalc.errorMessage;
      } else if (arrayTrue) {
        return '';
      }
      return mlOddsAnswerString;
    } else if (oddsTypeButtonSelectedId === 2) {
      if (isNaN(fracOddsAnswer)) {
        return parlayCalc.errorMessage;
      } else if (arrayTrue) {
        return '';
      }
      return fracOddsAnswerString;
    }
  };

  const returnTotalParlayOdds = () => {
    if (oddsTypeButtonSelectedId === 0) {
      if (isNaN(decOddsAnswer)) {
        return '';
      } else if (arrayTrue) {
        return '';
      }
      return decParlayOdds;
    } else if (oddsTypeButtonSelectedId === 1) {
      if (isNaN(mlOddsAnswer)) {
        return '';
      } else if (arrayTrue) {
        return '';
      }
      return mlParlayOdds;
    } else if (oddsTypeButtonSelectedId === 2) {
      if (isNaN(fracOddsAnswer)) {
        return '';
      } else if (arrayTrue) {
        return '';
      }
      return fracParlayOdds;
    }
  };

  const returnProfit = () => {
    let payout = 0;
    if (oddsTypeButtonSelectedId === 0) {
      if (isNaN(decOddsAnswer)) {
        return '';
      } else if (arrayTrue) {
        return '';
      }
      payout = decOddsAnswer;
    } else if (oddsTypeButtonSelectedId === 1) {
      if (isNaN(mlOddsAnswer)) {
        return '';
      } else if (arrayTrue) {
        return '';
      }
      payout = mlOddsAnswer;
    } else if (oddsTypeButtonSelectedId === 2) {
      if (isNaN(fracOddsAnswer)) {
        return '';
      } else if (arrayTrue) {
        return '';
      }
      payout = fracOddsAnswer;
    }

    const profit = payout - wagerAmount;
    const formattedProfit = `$${parlayCalc.formatOdds(Math.round(profit * 100) / 100)}`;
    return formattedProfit;
  };

  return (
    <>
      <div>
        {eventOdds.map((odds, index) => (
          <div key={index}>
            <label>
              {`Event ${index + 1} = `}
              <input
                type="text"
                value={odds}
                onChange={(e) => handleEventOddsChange(index, e.target.value)}
              />
            </label>
            <button onClick={() => eraseEventOdds(index)}>x</button>
          </div>
        ))}
        <div>
          <label>
            Wager Amount =
            <input type="text" value={wagerAmount} onChange={handleWagerChange} />
          </label>
        </div>
        <div>
          <h2>Payout = {returnPayout()}</h2>
        </div>
        <div>
          <h2>Profit = {returnProfit()}</h2>
        </div>
        <div>
          <h2>Parlay Odds = {returnTotalParlayOdds()}</h2>
        </div>
      </div>
      <div>
        <ButtonGroup
          buttons={["DECIMAL", "MONEYLINE", "FRACTIONAL"]}
          handleClick={handleOddsButtonClick}
          oddsTypeButtonSelectedId={oddsTypeButtonSelectedId}
        />
      </div>
      <div className="clearAllButtonContainer">
        <button className="clearAllButton" onClick={clearAll}>
          Clear All
        </button>
        <button className="clearAllButton" onClick={goBack}>
          Go Back
        </button>
      </div>
    </>
  );
}