export const parlayCalc = {
    errorMessage: "Please Enter Correct Odds",
  
    formatOdds(odds) {
      return odds.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  
    totalParlayMlOdds(totalParlayOdds) {
      const ans = `+${(totalParlayOdds - 1) * 100}`;
      return ans;
    },
  
    totalParlayFracOdds(totalParlayOdds) {
      const ans = `${totalParlayOdds}/1`;
      return this.formatOdds(ans);
    },
  
    multiplyArray(array, oddsType) {
      let sum = 1;
      for (let i = 0; i < array.length; i++) {
        const odds = array[i] === "" ? 1 : array[i];
        if (oddsType === "decimal") {
          if (Number(odds) >= 1) {
            sum *= odds;
          } else {
            return "error";
          }
        } else if (oddsType === "moneyline") {
          if (Number(odds) >= 100) {
            sum *= (Number(odds) + 100) / 100;
          } else if (Number(odds) <= -100) {
            sum *= 1 + Math.abs(100 / Number(odds));
          } else if (odds === 1) {
            sum *= odds;
          } else {
            return "error";
          }
        } else if (oddsType === "fractional") {
          if (String(odds).includes("/")) {
            const [num, dec] = odds.split("/");
            if (dec < 0 || num < 0) {
              return "error";
            } else if (num / dec >= 1) {
              odds = num / dec + 1;
              if (!isFinite(odds)) {
                return "error";
              } else {
                sum *= odds;
              }
            } else if (num / dec < 1) {
              odds = num / dec + 1;
              sum *= odds;
            }
          } else if (odds < 1) {
            return "error";
          } else if (odds === 1 || !isNaN(odds)) {
            sum *= odds;
          } else {
            return "error";
          }
        }
      }
      return sum;
    },
  
    multiplyDecArray(array) {
      return this.multiplyArray(array, "decimal");
    },
  
    multiplyMlArray(array) {
      return this.multiplyArray(array, "moneyline");
    },
  
    multiplyFracArray(array) {
      return this.multiplyArray(array, "fractional");
    },
  };