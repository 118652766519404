// ArbForm.js
import { useState } from "react";
import ButtonGroup from "./ButtonGroup";
import { arbitrageCalculator } from "./ArbitrageCalculator";
import './formstyles.css';
import { useNavigate } from "react-router-dom";

export function ArbForm() {
  const navigate = useNavigate();
  const goBack = () => navigate("/");
  const [eventOdds, setEventOdds] = useState(['', '', '', '', '']);
  const [oddsTypeButtonSelectedId, setOddsTypeButtonSelectedId] = useState(1);
  const [wagerAmount, setWagerAmount] = useState(100);

  const handleOddsChange = (index, value) => {
    setEventOdds(prevOdds => {
      const newOdds = [...prevOdds];
      newOdds[index] = value;
      return newOdds;
    });
  };

  const handleOddsButtonClick = (oddsButtonIndex) => setOddsTypeButtonSelectedId(oddsButtonIndex);
  const handleWagerChange = (e) => setWagerAmount(e.target.value);

  const clearAll = () => {
    setEventOdds(['', '', '', '', '']);
    setWagerAmount(100);
  };

  const getConvertedOdds = (odds) => {
    if (oddsTypeButtonSelectedId === 1) return arbitrageCalculator.convertMlToDec(odds);
    if (oddsTypeButtonSelectedId === 2) return arbitrageCalculator.convertFracToDec(odds);
    return odds;
  };

  const arbExists = () => arbitrageCalculator.checkArb(eventOdds.filter(odds => odds !== '').map(getConvertedOdds));

  const getPercent = (index) => {
    if (!arbExists()) return null;
    const odds = eventOdds.filter(odds => odds !== '').map(getConvertedOdds);
    const percent = arbitrageCalculator.calculatePercentage(odds, index);
    return oddsTypeButtonSelectedId === 0 ? Math.round(percent * 100) : Math.round(percent * 10000) / 100;
  };

  const getWager = (index) => {
    if (!arbExists()) return null;
    const odds = eventOdds.filter(odds => odds !== '').map(getConvertedOdds);
    const percent = arbitrageCalculator.calculatePercentage(odds, index);
    return Math.round(percent * wagerAmount * 100) / 100;
  };

  const returnProfit = () => {
    if (!arbExists()) return null;
    const odds = eventOdds.filter(odds => odds !== '').map(getConvertedOdds);
    const percent = arbitrageCalculator.calculatePercentage(odds, 0);
    return Math.round(odds[0] * percent * wagerAmount - wagerAmount);
  };

  return (
    <>
      {eventOdds.map((odds, index) => (
        <div key={index}>
          <label>
            {`Event ${index + 1} = `}
            <input type="text" value={odds} onChange={e => handleOddsChange(index, e.target.value)} />
          </label>
          <label>{getPercent(index)}%</label>
          <label>-----${getWager(index)}</label>
        </div>
      ))}
      <div>
        <label>
          Wager Amount =
          <input type="text" value={wagerAmount} onChange={handleWagerChange} />
        </label>
      </div>
      <div>
        <h2>Profit = ${returnProfit()}</h2>
      </div>
      <div>
        <ButtonGroup buttons={["DECIMAL", "MONEYLINE", "FRACTIONAL"]} handleClick={handleOddsButtonClick} oddsTypeButtonSelectedId={oddsTypeButtonSelectedId} />
      </div>
      <div className="clearAllButtonContainer">
        <button className="clearAllButton" onClick={clearAll}>Clear All</button>
        <button className="clearAllButton" onClick={goBack}>Go Back</button>
      </div>
    </>
  );
}