export const arbitrageCalculator = {
    checkArb(eventOdds) {
      const positiveArbitrage = 1 - eventOdds.reduce((sum, odds) => sum + 1 / odds, 0);
      return positiveArbitrage > 0 ? "Yes" : "No";
    },
  
    calculatePercentage(eventOdds, index) {
      const totalPercent = eventOdds.reduce((sum, odds) => sum + 1 / odds, 0);
      return (1 / eventOdds[index]) / totalPercent;
    },
  
    convertMlToDec(odds) {
      return odds >= 100 ? odds / 100 + 1 : 100 / Math.abs(odds) + 1;
    },
  
    convertFracToDec(odds) {
      if (odds.includes("/")) {
        const [num, dec] = odds.split("/").map(Number);
        if (dec <= 0 || num <= 0) return "error";
        return num / dec + 1;
      }
    },
  };